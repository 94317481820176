import React from "react";

import AddressInput from "~/components/Scheduling/AddressInput";
import AdminInput from "~/components/Scheduling/AdminInput";
import AdminSelector from "~/components/Scheduling/AdminSelector";
import AppointmentSummary from "~/components/Scheduling/AppointmentSummary";
import DateInput from "~/components/Scheduling/DateInput";
import PartnerSelector from "~/components/Scheduling/PartnetSelector";
import PatientData from "~/components/Scheduling/PatientData";
import SelectLocale from "~/components/Scheduling/SelectLocale";
import AssistedSchedule from "~/pages/offline-appointment/assisted-schedule";

interface AppointmentAssistedScheduleProps {
  id: string;
}

const AppointmentAssistedSchedule = ({ id }: AppointmentAssistedScheduleProps) => {
  const steps = {
    0: <SelectLocale />,
    1: <PartnerSelector />,
    2: <AddressInput />,
    3: <DateInput />,
    4: <AdminSelector />,
    5: <AdminInput />,
    6: <PatientData />,
    7: <AppointmentSummary />,
  };

  return (
    <AssistedSchedule
      id={id}
      steps={steps}
    />
  );
};

export default AppointmentAssistedSchedule;
